const _ = require('lodash');

export const coFounders = [
  // LINNA ZHA
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01357009',
    email: 'linnazha@yahoo.com',
    name: 'Linna Zha',
    nameCn: 'Linna Zha',
    nickname: 'zha_linna',
    phone: '(949) 683 7988',
    role: 'Co-Founder',
    roleCn: '创始合伙人',
  },
  // MIN ZHANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01714040',
    email: 'minzhangonly@gmail.com',
    linkedin: 'https://www.linkedin.com/in/minzhangrealtor/',
    name: 'Min Zhang',
    nameCn: '张敏',
    nickname: 'zhang_min',
    phone: '(949) 331 4366',
    role: 'Co-Founder',
    roleCn: '创始合伙人',
    zillow: 'https://www.zillow.com/profile/minzhangrealtor',
    bio: `Min is a top Real Estate agent in Orange County, having received numerous awards as well as a Founding Partner of Alink Real Estate. 
          
          With over 19 years of experience helping clients buy and sell homes, Min prides herself in having the utmost regard for her clients' confidentiality and privacy. By pairing her unrelenting work ethic and a keen insight for market trends, Min tailors her marketing strategies to best fit each individual client. Min has made an impression the clients she's represented, earning consistent repeat and referral business for her attention to detail and unwavering work ethic. 
          
          Min is devoted to serving the needs of real estate buyers and sellers in destinations including Irvine, Newport Beach, Newport Coast, Mission Viejo, and Laguna Beach. She looks forward to introducing you to the communities and lifestyle of some of Orange County finest neighborhoods!`,
  },
  // CHRISTINE LI
  {
    brokerLicNumber: '02144450',
    email: 'christine.harvest@gmail.com',
    name: 'Christine Li',
    nameCn: '李薇',
    nickname: 'li_christine',
    phone: '(310) 985 9956',
    role: 'Co-Founder',
    roleCn: '创始合伙人',
  },
];

export const teamMembers = [
  // ANNA PENG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02054173',
    email: 'genstrongrealty@gmail.com',
    name: 'Anna Peng',
    nameCn: 'Peng Yingzi',
    nickname: 'peng_anna',
    phone: '(949) 689 8921',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // BAICHAO YANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02193261',
    email: 'baichao617@gmail.com',
    name: 'Baichao Yang',
    nameCn: 'Yang Baichao',
    nickname: 'yang_baichao',
    phone: '(626) 228 7647',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // BOB SHI
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02126026',
    email: 'bob.yong.shi@gmail.com',
    name: 'Bob Shi',
    nameCn: '石勇',
    nickname: 'shi_yong',
    phone: '(949) 878 1231',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
    bio: `Bob has an Executive MBA and a strong background in business and finance, which helps to provide valuable insights and analysis to clients. He is also proud to call Orange County the home, and have a deep understanding of the residential and comercial market. His top priority is helping the clients achieve the best value for their property, whether they are buying, selling, or investing.
          
          He is skilled at conducting thorough market analyses and identifying the best opportunities for the clients. He is dedicated to providing exceptional service and building long-term relationships with the clients.`,
  },
  // CATHY JING KAISER
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01835475',
    email: 'CathyJKaiser@gmail.com',
    name: 'Cathy Jing Kaiser',
    nameCn: '凯栖',
    nickname: 'kaiser_cathy',
    phone: '(949) 202 9626',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
    bio: `I'm an experienced top producing real estate agent and a member of California Association of Realtors with a Master Degree in Management Science. Real estate is not only my job, but my passion! I have lived in Orange County, California (Irvine, Lake Forest) for over 35 years and love living here. 
          I always put my best effort to understand my client's needs and communicate well to all parties involved in the real estate transaction process to get the job done! I treat each and every sale as if it were my own. 
          My past real estate appraisal experience has helped me to understand best of the real estate market and assist my clients to make purchase decisions that are best for their unique situation, and to sell their properties quickly and for the highest possible price. 
          I am fluent in English and Mandarin and knowledgeable with international clients. Customer's real estate need has always been my highest priority. 
          
          Experience and Achievements: 
          Senior Partner: Alink Realty Group (2024 - Present)
          
          Top Producer Award: Realty One Group (2017 - 2024)
          
          Part-time real estate investor: Presidential Real Estate, LLC (2010 - 2017)
          
          I'm a proud mother of two children, and am active in community service, Chinese folk dance. I have lived in Irvine since 1994, and I'm more than happy to provide insights on neighborhood, community, education, and property management.`,
  },
  //CHANGHUI YUAN
  {
    brokerLicNumber: '02144450',
    realtorDRE: '',
    email: '',
    name: 'Changhui Yuan',
    nameCn: 'Yuan Changhui',
    nickname: 'yuan_changhui',
    phone: '',
    role: '',
    roleCn: '',
  },
  // CHENGGANG MA
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02235706',
    email: 'mac013056@gmail.com',
    name: 'Chenggang Ma',
    nameCn: 'Ma Chenggang',
    nickname: 'ma_chenggang',
    phone: '(949) 866 7256',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // DEB XIE
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02133789',
    email: 'debora.xie@gmail.com',
    name: 'Deb Xie',
    nameCn: 'Deb Xie',
    nickname: 'xie_deb',
    phone: '(949) 870 6538',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // DEREK JIANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02193400',
    email: 'qyjiang777@gmail.com',
    name: 'Derek Jiang',
    nameCn: 'Derek Jiang',
    nickname: 'jiang_derek',
    phone: '(657) 610 9969',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // ELAINE ZENG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01964490',
    email: 'zengyinong@gmail.com',
    name: 'Elaine Zeng',
    nameCn: '译侬',
    nickname: 'zeng_elaine',
    phone: '(510) 926 5288',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // GRACE DING
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01876205',
    email: 'graceding2010@gmail.com',
    name: 'Grace Ding',
    nameCn: 'Grace Ding',
    nickname: 'ding_grace',
    phone: '(949) 390 3178',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // IRIS LI
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02152979',
    email: 'iris@irislire.com',
    name: 'Iris Li',
    nameCn: 'Iris Li',
    nickname: 'li_iris',
    phone: '(948) 868 6999',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // JENNY WILSON
  {
    brokerLicNumber: '02144450',
    realtorDRE: '',
    email: '',
    name: 'Jenny Wilson',
    nameCn: 'Wilson Jenny',
    nickname: 'wilson_jenny',
    phone: '',
    role: '',
    roleCn: '',
  },
  // JIANMIN DONG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02153346',
    email: 'carol68866@gmail.com',
    name: 'Jianmin Dong',
    nameCn: 'Dong Jianmin',
    nickname: 'dong_jianmin',
    phone: '(714) 234 3416',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // JIN ZHANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01882323',
    email: 'zhangjin2000@gmail.com',
    name: 'Jin Zhang',
    nameCn: 'Jin Zhang',
    nickname: 'zhang_jin',
    phone: '(626) 893 9760',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // JUSTIN YANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02177657',
    email: 'zhigang-yang@outlook.com',
    name: 'Justin Yang',
    nameCn: '杨志刚',
    nickname: 'yang_justin',
    phone: '(949) 200 6177',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
    bio: `Justin Yang is a dedicated and results-driven realtor serving Orange County, with a primary focus on Irvine and Newport Coast. With a strong academic foundation, he holds dual bachelor's degrees from Shanghai Jiao Tong University and master's degree from Peking University Guanghua School of Management. He has also studied the real estate industry under a renowned professor, equipping him with deep expertise and a keen analytical approach. 
          
          Justin's career is built on professionalism, dedication, and trust. His experience working in top 10 Fortune 500 companies has given him a global perspective and a strong foundation in management, further enhancing his ability to serve clients with strategic insight and integrity. He approaches real estate with passion, not only because he loves the title of a realtor but because he genuinely enjoys helping clients navigate one of the most significant decisions of their lives. 
          
          For Justin, every client is the top priority. He is committed to crafting the best solutions tailored to each individual's needs ensuring a seamless and rewarding real estate experience. His guiding principle is a true win-win philosophy - where his success is only measured by his clients' satisfaction. 
          
          Beyond real estate, Justin's personal journey of raising two golf-playing sons has given him a unique perspective on discipline, patience, and long-term vision - qualities he brings to every client relationship. When you look back and realize that choosing him as your partner was the best real estate decision you ever made - perhaps even better than your choice of the property itself - that, to Justin, is the ultimate success.`,
  },
  // LANE LIU
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02229090',
    email: 'lanehomesusa@gmail.com',
    name: 'Lane Liu',
    nameCn: 'Lane Liu',
    nickname: 'liu_lane',
    phone: '(949) 520 0222',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // LILI SUN
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02037381',
    email: 'lilisun529@gmail.com',
    name: 'Lili Sun',
    nameCn: 'Lili Sun',
    nickname: 'sun_lili',
    phone: '(949) 981 9388',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // LIN KENNY
  {
    brokerLicNumber: '02144450',
    realtorDRE: '0145369',
    email: 'linkenny88@yahoo.com',
    name: 'Lin Kenny',
    nameCn: '安琳',
    nickname: 'kenny_lin',
    phone: '(949) 202 8061',
    role: 'Senior Partner',
    roleCn: '高级合伙人',
    zillow: 'https://www.zillow.com/profile/linkenny',
    bio: `With 16 years of experience as a full-time licensed realtor, I have provided the highest standard of customer service to buyers, sellers, and agents. I have achieved numerous awards and became the Senior Partner of Alink Realty Group. I am fluent in English, Mandarin, and Cantonese, and I have a wealth of international marketing expertise which enables me to represent diverse clients from China, Taiwan, Canada, Singapore, as well as locally. My goal is to meet the diverse needs of clients from around the world by creating customized services in the real estate business. My team has provided specialized services for clients in new homes, resale properties, and commercial investments, ensuring our clients receive high-quality service in analyzing investment directions, selecting properties, making purchases, designing, obtaining loans, and insurance.

          Senior Partner of Alink Realty Group 2023 
          
          Top Sales of Pinnacle Real Estate Group 2022 
          
          Top Sales of the Year - Realty One Group 2012 - 2020 
          
          Top Sales of the Year - Presidential Realty 2008 - 2012 
          
          Member of the Million-Dollar Commission Club from 2012 to 2023 
          
          Trusted real estate consultant by prominent builders: Toll Brothers, Irvine Company, Lennar, Shea Home, Pulte Home.
          
          In addition to my expertise, I have been a resident in Orange County since 2003. I am more than happy to provide insights on neighborhoods, communities, and education. I look forward to serving you and your family!`,
  },
  // LUCY LI
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02047202',
    email: 'lucyusarealestate@gmail.com',
    name: 'Lucy Li',
    nameCn: 'Lucy Li',
    nickname: 'li_lucy',
    phone: '(949) 562 9688',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // LYLA YU
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02112158',
    email: 'Lyla.Yu@toshahome.com',
    name: 'Lyla Yu',
    nameCn: 'Yuhan Yu',
    nickname: 'yu_yuhan',
    phone: '(424) 522 9333',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // LYNNE WU
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01961215',
    email: 'Lynne@LWUhomes.com',
    name: 'Lynne Wu',
    nameCn: 'Lynne Wu',
    nickname: 'wu_lynne',
    phone: '(949) 878 1697',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // NANCY LIN
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01820248',
    email: 'nancy1125lin@gmail.com',
    name: 'Nancy Lin',
    nameCn: 'Nancy Lin',
    nickname: 'lin_nancy',
    phone: '(714) 319 6566',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
    bio: `In 2006, Nancy started her real estate career as a transaction reviewer and has developed a strong knowledge in real estate laws. Nancy has leveraged her knowledge, experience, and unrivaled work ethic to drive her repeated success in representing sellers, buyers, landlords, tenants, and investors in the Orange County market.
          
          Nancy has a long track record of getting the top dollar for her listings and helping customers net the most out of any transaction. She is dedicated to meeting your real estate needs by providing you the best information, local expertise, and customer service.`,
  },
  // REINA JIANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01891687',
    email: 'reinajiang27@gmail.com',
    name: 'Reina Jiang',
    nameCn: 'Reina (Yuru) Jiang',
    nickname: 'jiang_reina',
    phone: '(714) 928 2005',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // RUOYING YUEH
  {
    brokerLicNumber: '02144450',
    realtorDRE: '',
    email: '',
    name: 'Ruoying Yueh',
    nameCn: 'Yueh RuoYing',
    nickname: 'yueh_ruoying',
    phone: '',
    role: '',
    roleCn: '',
  },
  // SHERRY SU
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02105411',
    email: 'SherrySu6802@gmail.com',
    name: 'Sherry Su',
    nameCn: 'Sherry Su',
    nickname: 'su_sherry',
    phone: '(949) 880 6996',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // SONGZI LI
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02180021',
    email: 'hello@songzili.com',
    linkedin: 'https://www.linkedin.com/in/songzi-li/',
    name: 'Songzi Li',
    nameCn: '李松子',
    nickname: 'li_songzi',
    phone: '(949) 518 0888',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
    xiaohongshu:
      'https://www.xiaohongshu.com/user/profile/62a10db6000000001902a3d6?xhsshare=WeixinSession&appuid=62a10db6000000001902a3d6&apptime=1698985795',
  },
  // SUNNIE LIN
  {
    brokerLicNumber: '02144450',
    realtorDRE: '2165462',
    email: 'sunny.xi.lin@gmail.com',
    name: 'Sunnie Lin',
    nameCn: 'Sunnie Lin',
    nickname: 'lin_sunnie',
    phone: '(858) 900 6668',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
    bio: `Sunnie, an experienced realtor with a wealth of knowledge and expertise in the field. Sunnie holds a MA degree from Wuhan University and has worked as a director and reporter at Phoenix TV and CNS（China News Service）. In addition, Sunnie has also worked as a brand manager at one of the top 30 real estate developers in China, giving them a unique perspective on the industry.

          With over 12 years of experience in real estate in both China and the United States, Sunnie is a top agent known for providing high-quality service to their clients. Whether you're looking to buy or sell a property, Sunnie's extensive knowledge and expertise can help guide you through the process and ensure a smooth and successful transaction.`,
  },
  // SUSAN ZHAO
  {
    brokerLicNumber: '02144450',
    realtorDRE: '',
    email: '',
    name: 'Susan Zhao',
    nameCn: 'Zhao Susan',
    nickname: 'zhao_susan',
    phone: '',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // VICTORIA CHEN
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01879108',
    email: 'vivicqy@gmail.com',
    name: 'Victoria Chen',
    nameCn: '陳麒宇',
    nickname: 'chen_victoria',
    phone: '(909) 828 8088',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // XIAOYING GAO
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02238436',
    email: 'helenagao99@gmail.com',
    name: 'Helena Gao',
    nameCn: 'Gao Xiaoying',
    nickname: 'gao_xiaoying',
    phone: '(949) 331 8002',
    role: 'Realtor',
    roleCn: '经纪人',
  },
  // XUANMIN ZHANG
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01958202',
    email: 'zhang5998893@gmail.com',
    name: 'Xuanmin Zhang',
    nameCn: 'Zhang Xuanmin',
    nickname: 'zhang_xuanmin',
    phone: '(949) 599 8893',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
  // YAN YE
  {
    brokerLicNumber: '02144450',
    realtorDRE: '01931113',
    email: 'yanye99@gmail.com',
    name: 'Yan Ye',
    nameCn: 'Yan Ye',
    nickname: 'ye_yan',
    phone: '(949) 202 0236',
    role: 'Senior Partner | Realtor',
    roleCn: '高级合伙人 | 经纪人',
  },
  // YUE WU
  {
    brokerLicNumber: '02144450',
    realtorDRE: '02059375',
    email: 'yuewurel2020@gmail.com',
    name: 'Yue Wu',
    nameCn: 'Wu Yue',
    nickname: 'wu_yue',
    phone: '(408) 644 8269',
    role: 'Partner | Realtor',
    roleCn: '合伙人 | 经纪人',
  },
];

export const membersByNickname = _.keyBy(
  teamMembers.concat(coFounders),
  'nickname',
);
